<template>
  <div>
    <v-row>
      <v-col lg="12">
      <incidencecontainer :isAdmin="true"  :height="200">> 
      </incidencecontainer>
      
      </v-col>
    </v-row>
  </div>
</template>

<script>
import incidencecontainer from '@/views/FreshProduction/IncidenceContainer/IncidenceContainer.vue';

export default {
  components: {        
    incidencecontainer
    },

   data() {
    return {
      parentID: 0,
      incID:0, 
      trnState:0,     
    };
  },
  methods: {
 
  },
};
</script>
