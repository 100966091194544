import Service from "../Service";
import Vue from "vue";
const resource = "incContainer/"

export default {
    save(prflin, requestID) {
          return Service.post(resource + "save", prflin, {
          params: { requestID: requestID },
        });
      },
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
        params: { requestID: requestID },
    });
  },
  
  listContainer(RequestID){    
    return Service.post(resource + "listContainer",{},{
    params:{RequestID: RequestID}
  });
 
},

GetUrllistContainer(){
  return Vue.prototype.$http.defaults.baseURL + "/incContainer/listContainer";
},

};