<template>
  
    <s-crud @returnObject='refresh($event)'
    no-border
    title="Incidencias por Contenedor"
    :config="config"
    @save="save($event)"
    add
    @clearForm="clearForm()"
    @rowSelected="rowSelected($event)"
    remove    
    :filter="filter"
    search-input  
    ref="crud"
    >
   <template scope="props">
       <v-card-text class="pa-0" >
        <v-row justify="center">

          <v-col cols="4" class="s-col-form" justfy="center" style="margin: initial;"  lg="4">
                <v-card class="pa-2" >
            <s-select-definition v-model="props.item.TypeInc" :def="1294" label="Tipo"></s-select-definition>
             </v-card>
          </v-col>
           <v-col cols="4" class="s-col-form" justfy="center" style="margin: initial;"  lg="4">
            <v-card class="pa-2">
            <s-text v-model="props.item.IncDescription" label="Descripción" > </s-text> 
             </v-card>
           </v-col>

 <v-col cols="4" class="s-col-form" justfy="center" style="margin: initial;"  lg="4">
            <v-card class="pa-2">
           <sselectcontainer v-model="props.item.CntID"label="Contenedor" ></sselectcontainer>
             </v-card>
           </v-col>
           
        </v-row> 
       
      </v-card-text>  
         </template>
           <template v-slot:SecStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
          {{ row.SecStatus == 1 ? "Registrada" : "Eliminada" }}
        </v-chip>
      </template>

           
     </s-crud>

</template>


<script>
import _service from "@/services/FreshProduction/IncidenceContainerService";
import sselectcontainer from "@/components/FreshProduction/Container/sSelectContainer.vue";
export default {
  name: "RequirementServiceIncidenceContainer",
  components: { sselectcontainer },
  props: {
    parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
      incID:0,
      dialogEdit: false,
       ataID: 0,  
       itemsSelect: [],
       itemsFarm: [], 
       filter: { },  
     };     
  },

  computed: {
   
    config() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          IncID: "ID",
          IncDescription: "string",         
          DedDescription: "string", 
          CntDescription: "string",
          SecStatus: "status",
          }, 
        service: _service,
        headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "IncID", sortable: false },
              { text: "Descripción", value: "IncDescription", sortable: false },
              { text: "Tipo", value: "DedDescription", sortable: false }, 
               { text: "Contenedor", value: "CntDescription", sortable: false },                
               { text: "Estado", value: "SecStatus", sortable: false }, 
                     
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
             { text: "ID", value: "IncID", sortable: false },
              { text: "Descripción", value: "IncDescription", sortable: false },
              { text: "Tipo", value: "DedDescription", sortable: false }, 
               { text: "Contenedor", value: "CntDescription", sortable: false }, 
               { text: "Estado", value: "SecStatus", sortable: false },      
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  

  methods: {

 Initialize() {
   //DedID: this.DedID -- base de datos: campo actual
     this.filter = {};
    },
   
    edit(item) {
      this.dialogEdit = true;
    
    },

    rowSelected(rows) {
      if (rows.length > 0) {
      this.incID = rows[0].IncID;
      this.$emit("rowSelected",  rows[0]); 
       this.itemsSelect=rows[0];
      }
    },

    save(item) {  

      if (item.IncDescription.length==0)
      {
             this.$fun.alert("Debe ingresar una descripcion para la actividad","warning")
             return;
      }

        item.SecStatus=1
        item.AtaStatus=1
        item.save();          
      
    },    
  },
  watch: {
    // este DedID es el que esta en variety, vista general en DedID  ="dedID"
    typeArea(){   
        
      this.Initialize();    
      },
  },
   created() {
    this.filter = { TypeArea: 0 };
  },
};
</script>
